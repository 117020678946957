#visualizer table {
  border-collapse: collapse;
}

#visualizer_board td {
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #555;
  padding: 0;
  font-family: robots;
  font-size: 24px;
}

#visualizer_board td.red_tile {
  background-color: #c0392b;
}

#visualizer_board td.green_tile {
  background-color: #27ae60;
}

#visualizer_board td.blue_tile {
  background-color: #2980b9;
}

#visualizer_buttons {
  text-align: center;
}

@font-face {
  font-family: robots;
  src: url("robot.6dbea770.ttf") format("truetype");
}

/*# sourceMappingURL=index.711b7704.css.map */

#visualizer table {
    border-collapse: collapse;
}

#visualizer_board td {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0px;
    font-family: 'robots';
    font-size: 24px;
    border: solid #555 1px;
}

#visualizer_board td.red_tile {
    background-color: #c0392b;
    /*background: linear-gradient(to top left, #ff0000, #993333);*/
}

#visualizer_board td.green_tile {
    background-color: #27ae60;
    /*background: linear-gradient(to top left, #00aa44, #337733);*/
}

#visualizer_board td.blue_tile {
    background-color: #2980b9;
    /*background: linear-gradient(to top left, #0044ff, #333399);*/
}

#visualizer_buttons {
    text-align: center;
}

@font-face {
    font-family: 'robots';
    src: url('./robot.ttf') format('truetype');
}